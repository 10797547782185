.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container1{
  position: relative;
  text-align: center;
  
}
.container1 .centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}

.image-container{
  height: 200px;
  width: 320px;
}

.header-top{
  background-color: black;
  justify-content: center;
  text-align: center;
  color: white;
  height: 50px;
  
}
.design{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  padding-top: 20px;
  top: 0px;
  left: 0px;
}
@media only screen and (max-width: 768px){
.header-top{
  /* height:125px; */
  display: none;
}
.navbar-toggle{
  margin-right: 10px;
  margin-bottom: 20px;
  
  
}
/* .design{
  display: flex;
  flex-direction: column;
} */

/* .num{
  margin-bottom:10px ;
} */
/* .mail{
  margin-bottom:10px ;
} */
}


.HeaderContainer{
  position: fixed;
  z-index: 99;
  width: 100%;
}
.container1{
  margin-top: 120px;
}
@media only screen and (min-width: 760px) {
  .wt-bnr-inr {
    height:50vh;
  }
  .ImgMar{
    margin-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
.container1 img{
  height: 50vh;
  /* max-width: 50%; */
}
.container1{
  margin-top: 0px !important;
}
.container1 .centered{
  top:60%;
}
}
@media only screen and (max-width: 992px) and (min-width: 768px)  {
  .ImgMar{
    margin-top: 10px;
  }
}


